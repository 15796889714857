import * as React from "react"
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import PageBuilder from "../builder/page-builder";
import Hero from "../builder/hero"

const IndexPage = ({ data }) => {
  const page = data.landing
  const index = data.index
  return (
    <Layout>
      <HelmetDatoCms 
        seo={page.seoMetaTags}
      />
      <Hero data={index} />
      <PageBuilder data={index.content} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
query {
  landing: datoCmsLandingPage {
    heroEventLogo
    heroLogoText
    heroSubtitle
    heroButtonText
    heroFindOutMore
    heroBackgroundImage {
      url(imgixParams: {w: "1920"})
    }
    heroImage {
      url(imgixParams: {h: "1080"})
      alt
    }

    bottomComingSoon
    bottomEmail
    bottomTickets


    secondBackgroundImage {
      url(imgixParams: {w: "960"})
    }
    secondImage {
      url(imgixParams: {h: "1080"})
      alt
    }
    secondContent {
      blocks
      links
      value
    }

    social {
      url
      name
    }

    seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
    }
  }
  index: datoCmsHomepage {
    heroSize
    
    heroBackground {
      ... on DatoCmsBackgroundImage {
          __typename
          scrim
          imageBackground {
              url(imgixParams: {w: "1920"})
          }
          imageForeground {
              url(imgixParams: {w: "1080"})
          }
      }
      ... on DatoCmsBackgroundVideo {
          __typename
          scrim
          link
          linkMobile
          videoPoster {
              url(imgixParams: {w: "1440"})
          }
      }
    }
    heroContent {
      blocks {
          ... on DatoCmsIconContent {
              ...IconContent
          }
          ... on DatoCmsLogo {
              ...LogoContent
          }
      }
      links
      value
    }
    heroCtas {
      buttonType
      icon
      url
      text
      external
    }
    heroBottomItems {
      id
      icon
      url
      text
    }
    ...HomepageContent
  }
  site: datoCmsSite {
    name
  }
}
`